import axios, { AxiosError } from 'axios';
import { errorMessage } from '../helper/helper';

function httpErrorHandler(error: null | AxiosError) {
  if (error === null) throw new Error('Unrecoverable error!! Error is null!');

  if (axios.isAxiosError(error)) {
    const response = error?.response;

    switch (error.code) {
      case 'ERR_NETWORK': {
        errorMessage('Connection problems');
        break;
      }
      case 'ERR_CANCELED': {
        errorMessage('Connection canceled');
        break;
      }
    }

    if (response) {
      const statusCode = response?.status;

      switch (statusCode) {
        case 404: {
          console.error(
            'The requested resource does not exist or has been deleted',
          );
          break;
        }
        case 401: {
          console.error('Please login to access this resource');
          break;
        }
        case 500: {
          const error = response?.data?.error || response?.data?.stack;

          if (error) {
            errorMessage(error);
          }

          break;
        }
      }
    }
  }

  console.error(error.message);

  throw error;
}

export default httpErrorHandler;
